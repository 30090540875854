(function() {
    'use strict';

    angular.module('ionicPlaylist', [
        'ionic',
        'ionicAlert',
        'ionicConfirmation',
        'ionicData',
        'ionicDataTable',
        'ionicFilter',
        'ionicInfiniteScroll',
        'ionicLogin',
        'ionicOrderedList',
        'ionicRequest',
        'ionicTag',
        'ionicUser',
        'ionicVideo',
        'uabDefaultImage',
        'uabDefaultVariable',
        'uabEnvironment',
        'uabPromiseManagement',
        'uabRedirect'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicPlaylist').service(
        'AssignedPlaylistListService',
        AssignedPlaylistListService
    );

    AssignedPlaylistListService.$inject = [
        'ListManagementService',
        'LoginService',
        'PlaylistListService',
        'RequestManagementService',
        'UserDurationService'
    ];

    function AssignedPlaylistListService(
        ListManagementService,
        LoginService,
        PlaylistListService,
        RequestManagementService,
        UserDurationService
    ) {
        var AssignedPlaylistListService = this;

        AssignedPlaylistListService.getMore = getMore;
        function getMore(options) {
            return AssignedPlaylistListService.getPlaylistOptions(options).then(
                function(options) {
                    return ListManager.getMore(options);
                }
            );
        }

        AssignedPlaylistListService.getPage = getPage;
        function getPage(options) {
            return AssignedPlaylistListService.getPlaylistOptions(options).then(
                function(options) {
                    return ListManager.getPage(options);
                }
            );
        }

        AssignedPlaylistListService.getPlaylistOptions = getPlaylistOptions;
        function getPlaylistOptions(options) {
            return LoginService.getActiveUser().then(
                function(activeUser) {
                    options = RequestManagementService.getRequest(options);

                    options = RequestManagementService.setModel(options, 'playlists');
                    options = RequestManagementService.setAction(options, 'recommended');

                    if (activeUser) {
                        options = RequestManagementService.setParams(
                            options,
                            [
                                'userId=' + activeUser.id,
                                'week<=' + UserDurationService.getWeeks(activeUser),
                                'Images',
                                'Tags',
                                'Videos'
                            ]
                        );
                    }

                    return options;
                }
            );
        }

        AssignedPlaylistListService.removeEmptyPlaylists = removeEmptyPlaylists;
        function removeEmptyPlaylists(playlist) {
            return PlaylistListService.removeEmptyPlaylists(playlist);
        }

        AssignedPlaylistListService.reset = reset;
        function reset() {
            ListManager.reset();
        }

        var ListManager = ListManagementService.getListManager();

        AssignedPlaylistListService.reset();

        return AssignedPlaylistListService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicPlaylist').service(
        'PlaylistService',
        PlaylistService
    );

    PlaylistService.$inject = [
        'DataManagementService',
        'DefaultVariableService',
        'EnvironmentService',
        'ErrorService',
        'PlaylistListService',
        'RequestManagementService',
        'UserService',
        'UserDurationService'
    ];

    function PlaylistService(
        DataManagementService,
        DefaultVariableService,
        EnvironmentService,
        ErrorService,
        PlaylistListService,
        RequestManagementService,
        UserService,
        UserDurationService
    ) {
        var PlaylistService = this;

        PlaylistService.getPlaylist = getPlaylist;
        function getPlaylist(options) {
            var playlistId = false;
            if (DefaultVariableService.isObject(options)) {
                playlistId = DefaultVariableService.getInteger(options.id, 0);

                options = {};
            } else {
                playlistId = RequestManagementService.getId(options);
            }

            if (playlistId === 0) {
                playlistId = EnvironmentService.get('playlist_id');
            }

            if (playlistId) {
                options = RequestManagementService.getRequest(options);

                options = RequestManagementService.setParam(
                    options,
                    'id=' + playlistId
                );

                return PlaylistService.loadPlaylist(options);
            } else {
                return ListManager.getDefault().then(
                    function(playlist) {
                        if (playlist) {
                            return PlaylistService.getPlaylist(playlist);
                        }

                        return ErrorService.addError('COULD NOT FIND PLAYLIST.');
                    }
                );
            }
        }

        PlaylistService.getWeeks = getWeeks;
        function getWeeks() {
            return UserService.getUser().then(
                function(user) {
                    if (user) {
                        return UserDurationService.getWeeks();
                    }

                    return false;
                }
            );
        }

        PlaylistService.loadPlaylist = loadPlaylist;
        function loadPlaylist(options) {
            options = RequestManagementService.getRequest(options);

            options = RequestManagementService.setModel(options, 'playlists');
            options = RequestManagementService.setAction(options, 'get');

            options = RequestManagementService.setParams(
                options,
                [
                    'Images',
                    'Tags'
                ]
            );

            return ListManager.get(options).then(
                function(data) {
                    if (data) {
                        var playlist = data.playlists;

                        if (playlist) {
                            var videos = DefaultVariableService.getArray(playlist.videos);
                            if (videos.length === 0) {
                                return PlaylistService.loadVideos(playlist);
                            }

                            return playlist;
                        }
                    }

                    return ErrorService.addWarning('UNABLE TO LOAD PLAYLISTS.');
                }
            );
        }

        PlaylistService.loadVideos = loadVideos;
        function loadVideos(playlist) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'playlists_videos');
            options = RequestManagementService.setAction(options, 'get');

            options = RequestManagementService.setParams(
                options,
                [
                    'playlist_id=' + playlist.id,
                    'Videos'
                ]
            );

            options = RequestManagementService.setOrder(options, 'position');

            return DataManagementService.request(options).then(
                function(data) {
                    if (data) {
                        var playlistsVideos = DefaultVariableService.getArray(
                            data.playlists_videos
                        );

                        playlist.playlists_videos = playlistsVideos;

                        playlist.videos = PlaylistService.parseVideos(
                            playlistsVideos
                        );
                    }

                    return playlist;
                }
            );
        }

        PlaylistService.parseVideos = parseVideos;
        function parseVideos(playlistsVideos) {
            var videos = [];

            var playlistsVideosLength = playlistsVideos.length;
            for (var i = 0; i < playlistsVideosLength; i++) {
                var video = DefaultVariableService.getObject(
                    playlistsVideos[i].video
                );

                if (video.id !== 0) {
                    videos.push(video);
                }
            }

            videos.sort(
                function(a, b) {
                    var aPlaylistVideo = DefaultVariableService.getEmptyObject(
                        a._joinData
                    );

                    var aValue = DefaultVariableService.getInteger(
                        aPlaylistVideo.position,
                        999
                    );

                    var bPlaylistVideo = DefaultVariableService.getEmptyObject(
                        b._joinData
                    );

                    var bValue = DefaultVariableService.getInteger(
                        bPlaylistVideo.position,
                        999
                    );

                    return aValue - bValue;
                }
            );

            return videos;
        }

        PlaylistService.reset = reset;
        function reset() {

        }

        var ListManager = PlaylistListService.getListManager();

        PlaylistService.reset();

        return PlaylistService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicPlaylist').service(
        'PlaylistCreateService',
        PlaylistCreateService
    );

    PlaylistCreateService.$inject = [
        'PlaylistListService',
        'RequestManagementService'
    ];

    function PlaylistCreateService(
        PlaylistListService,
        RequestManagementService
    ) {
        var PlaylistCreateService = this;

        PlaylistCreateService.createPlaylist = createPlaylist;
        function createPlaylist(newPlaylist) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'playlists');
            options = RequestManagementService.setAction(options, 'add');

            options = RequestManagementService.setData(
                options,
                newPlaylist
            );

            return ListManager.create(options);
        }

        PlaylistCreateService.createPlaylistVideo = createPlaylistVideo;
        function createPlaylistVideo(newPlaylistVideo) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'playlists_videos');
            options = RequestManagementService.setAction(options, 'add');

            options = RequestManagementService.setData(
                options,
                newPlaylistVideo
            );

            return ListManager.create(options);
        }

        PlaylistCreateService.getListManager = getListManager;
        function getListManager() {
            return ListManager;
        }

        PlaylistCreateService.reset = reset;
        function reset() {

        }

        var ListManager = PlaylistListService.getListManager();

        PlaylistCreateService.reset();

        return PlaylistCreateService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicPlaylist').service(
        'PlaylistEditService',
        PlaylistEditService
    );

    PlaylistEditService.$inject = [
        'PlaylistService',
        'PlaylistListService',
        'RequestManagementService'
    ];

    function PlaylistEditService(
        PlaylistService,
        PlaylistListService,
        RequestManagementService
    ) {
        var PlaylistEditService = this;

        PlaylistEditService.deletePlaylist = deletePlaylist;
        function deletePlaylist(playlist) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'playlists');
            options = RequestManagementService.setAction(options, 'delete', playlist.id);

            return ListManager.remove(options);
        }

        PlaylistEditService.editPlaylist = editPlaylist;
        function editPlaylist(playlist) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'playlists');
            options = RequestManagementService.setAction(options, 'edit');

            options = RequestManagementService.setData(
                options,
                playlist
            );

            return ListManager.edit(options);
        }

        PlaylistEditService.editPlaylistVideo = editPlaylistVideo;
        function editPlaylistVideo(playlistVideo) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'playlists_videos');
            options = RequestManagementService.setAction(options, 'edit');

            options = RequestManagementService.setData(
                options,
                playlistVideo
            );

            return ListManager.edit(options);
        }

        PlaylistEditService.getPlaylist = getPlaylist;
        function getPlaylist(playlist) {
            return PlaylistService.getPlaylist(playlist);
        }

        PlaylistEditService.reset = reset;
        function reset() {

        }

        var ListManager = PlaylistListService.getListManager();

        PlaylistEditService.reset();

        return PlaylistEditService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicPlaylist').service(
        'PlaylistListService',
        PlaylistListService
    );

    PlaylistListService.$inject = [
        'DefaultVariableService',
        'ListManagementService',
        'RequestManagementService'
    ];

    function PlaylistListService(
        DefaultVariableService,
        ListManagementService,
        RequestManagementService
    ) {
        var PlaylistListService = this;

        PlaylistListService.buildOptions = buildOptions;
        function buildOptions(options) {
            options = RequestManagementService.getRequest(options);

            options = RequestManagementService.setModel(options, 'playlists');
            options = RequestManagementService.setAction(options, 'get');

            return options;
        }

        PlaylistListService.getListManager = getListManager;
        function getListManager() {
            return ListManager;
        }

        PlaylistListService.getMore = getMore;
        function getMore(options) {
            options = PlaylistListService.buildOptions(options);

            return ListManager.getMore(options);
        }

        PlaylistListService.getPage = getPage;
        function getPage(options) {
            options = PlaylistListService.buildOptions(options);

            return ListManager.getPage(options);
        }

        PlaylistListService.removeEmptyPlaylists = removeEmptyPlaylists;
        function removeEmptyPlaylists(playlists) {
            playlists = DefaultVariableService.getArray(playlists);

            var filteredPlaylists = [];

            var playlistsLength = playlists.length;
            for (var i = 0; i < playlistsLength; i++) {
                var suspect = DefaultVariableService.getObject(
                    playlists[i]
                );

                var videos = DefaultVariableService.getArray(
                    suspect.videos
                );

                if (videos.length !== 0) {
                    filteredPlaylists.push(suspect);
                }
            }

            return filteredPlaylists;
        }

        PlaylistListService.reset = reset;
        function reset() {
            ListManager.reset();
        }

        var ListManager = ListManagementService.getListManager(
            PlaylistListService.getPlaylists
        );

        PlaylistListService.reset();

        return PlaylistListService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicPlaylist').controller(
        'PlaylistCreateController',
        PlaylistCreateController
    );

    PlaylistCreateController.$inject = [
        'AlertService',
        'DefaultVariableService',
        'LoginService',
        'PlaylistCreateService',
        '$scope'
    ];

    function PlaylistCreateController(
        AlertService,
        DefaultVariableService,
        LoginService,
        PlaylistCreateService,
        $scope
    ) {
        var PlaylistCreateController = this;

        $scope.$watch(
            'onCancel',
            function(onCancel) {
                PlaylistCreateController.onCancel = DefaultVariableService.getPromise(
                    onCancel
                );
            }.bind(PlaylistCreateController)
        );

        $scope.$watch(
            'onCreate',
            function(onCreate) {
                PlaylistCreateController.onCreate = DefaultVariableService.getPromise(
                    onCreate
                );
            }.bind(PlaylistCreateController)
        );

        PlaylistCreateController.cancel = cancel;
        function cancel() {
            PlaylistCreateController.onCancel();
        }

        PlaylistCreateController.clear = clear;
        function clear() {
            PlaylistCreateController.reset();
            PlaylistCreateService.reset();
        }

        PlaylistCreateController.createPlaylist = createPlaylist;
        function createPlaylist() {
            PlaylistCreateController.isCreatingPlaylist = true;

            return PlaylistCreateService.createPlaylist(
                PlaylistCreateController.newPlaylist
            ).then(
                function(newPlaylist) {
                    if (newPlaylist) {
                        AlertService.addMessage('PLAYLIST CREATED.');

                        PlaylistCreateController.resetNewPlaylist();

                        PlaylistCreateController.onCreate(newPlaylist);
                    }

                    return newPlaylist;
                }
            ).finally(
                function () {
                    PlaylistCreateController.showCreator = false;

                    PlaylistCreateController.isCreatingPlaylist = false;
                }
            );
        }

        PlaylistCreateController.selectTags = selectTags;
        function selectTags(tags) {
            PlaylistCreateController.newPlaylist.tags = DefaultVariableService.getArray(
                tags
            );
        }

        PlaylistCreateController.reset = reset;
        function reset() {
            PlaylistCreateController.isCreatingPlaylis = false;

            PlaylistCreateController.resetNewPlaylist();

            PlaylistCreateController.showCreator = false;
        }

        PlaylistCreateController.resetNewPlaylist = resetNewPlaylist;
        function resetNewPlaylist() {
            PlaylistCreateController.newPlaylist = {};
            PlaylistCreateController.newPlaylist.tags = [];
        }

        PlaylistCreateController.init = init;
        function init() {
            PlaylistCreateController.reset();
        }

        LoginService.register(PlaylistCreateController);
    }
})();

(function() {
    'use strict';

    angular.module('ionicPlaylist').directive(
        'ionicPlaylistCreate',
        ionicPlaylistCreate
    );

    function ionicPlaylistCreate() {
        return {
            controller:   'PlaylistCreateController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                onCancel: '=',
                onCreate: '='
            },
            template:'<div><label class="item item-input item-floating-label col-xl-6"><span class="input-label">TITLE</span> <input type="text" placeholder="TITLE" data-ng-model="ctrl.newPlaylist.title"></label> <label class="item item-input item-floating-label col-xl-6"><span class="input-label">WEEK NUMBER</span> <input type="text" placeholder="WEEK NUMBER" data-ng-model="ctrl.newPlaylist.week"></label><div><ionic-upload target="ctrl.newPlaylist"></ionic-upload></div><label class="item item-input item-stacked-label"><span class="input-label">TAGS</span><ionic-tag-select on-select="ctrl.selectTags" target="ctrl.newPlaylist.tags"></ionic-tag-select></label><div class="row"><button class="button button-full accept-button col-xl-6" data-ng-click="ctrl.createPlaylist()" data-ng-disabled="ctrl.isCreatingPlaylist">{{ ctrl.isCreatingPlaylist ? \'CREATING...\' : \'CREATE\' }}</button> <button class="button button-full decline-button col-xl-6" data-ng-click="ctrl.cancel()" data-ng-disabled="ctrl.isCreatingPlaylist">CANCEL</button></div></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicPlaylist').controller(
        'PlaylistEditController',
        PlaylistEditController
    );

    PlaylistEditController.$inject = [
        'AlertService',
        'DefaultImageService',
        'DefaultVariableService',
        'LoginService',
        'IonicConfirmationService',
        'IonicPlaylistEditService',
        '$scope'
    ];

    function PlaylistEditController(
        AlertService,
        DefaultImageService,
        DefaultVariableService,
        LoginService,
        IonicConfirmationService,
        IonicPlaylistEditService,
        $scope
    ) {
        var PlaylistEditController = this;

        $scope.$watch(
            'onCancel',
            function(onCancel) {
                PlaylistEditController.onCancel = DefaultVariableService.getPromise(
                    onCancel
                );
            }.bind(PlaylistEditController)
        );

        $scope.$watch(
            'onDelete',
            function(onDelete) {
                PlaylistEditController.onDelete = DefaultVariableService.getPromise(
                    onDelete
                );
            }.bind(PlaylistEditController)
        );

        $scope.$watch(
            'onEdit',
            function(onEdit) {
                PlaylistEditController.onEdit = DefaultVariableService.getPromise(
                    onEdit
                );
            }.bind(PlaylistEditController)
        );

        $scope.$watch(
            'playlist',
            function(playlist) {
                PlaylistEditController.loadPlaylist(playlist);
            }.bind(PlaylistEditController)
        );

        PlaylistEditController.cancel = cancel;
        function cancel() {
            PlaylistEditController.onCancel();
        }

        PlaylistEditController.clear = clear;
        function clear() {
            PlaylistEditController.reset();
            IonicPlaylistEditService.reset();
        }

        PlaylistEditController.editPlaylist = editPlaylist;
        function editPlaylist() {
            PlaylistEditController.isEditingPlaylist = true;

            return IonicPlaylistEditService.editPlaylist(
                PlaylistEditController.playlist
            ).then(
                function(playlist) {
                    if (playlist) {
                        AlertService.addMessage('PLAYLIST EDITED.');

                        PlaylistEditController.onEdit(playlist);
                    }

                    return playlist;
                }
            ).finally(
                function () {
                    PlaylistEditController.isEditingPlaylist = false;
                }
            );
        }

        PlaylistEditController.deletePlaylist = deletePlaylist;
        function deletePlaylist() {
            PlaylistEditController.isDeletingPlaylist = true;

            return IonicConfirmationService.createConfirmation(
                'ARE YOU SURE YOU WANT TO DELETE THE PLAYLIST?',
                'DELETE',
                'CANCEL'
            ).then(
                function(canDelete) {
                    if (canDelete) {
                        return IonicPlaylistEditService.deletePlaylist(
                            PlaylistEditController.playlist
                        ).then(
                            function(success) {
                                if (success) {
                                    AlertService.addMessage('PLAYLIST DELETED.');

                                    PlaylistEditController.onDelete();
                                }

                                return success;
                            }
                        );
                    }

                    return canDelete;
                }
            ).finally(
                function() {
                    PlaylistEditController.isDeletingPlaylist = false;
                }
            );
        }

        PlaylistEditController.getPlaylistImage = getPlaylistImage;
        function getPlaylistImage(playlist) {
            return DefaultImageService.getPlaylistImage(playlist);
        }

        PlaylistEditController.getVideoImage = getVideoImage;
        function getVideoImage(playlist) {
            return DefaultImageService.getVideoImage(playlist);
        }

        PlaylistEditController.hasVideo = hasVideo;
        function hasVideo(video) {
            var videosLength = PlaylistEditController.playlist.videos.length;
            if (videosLength > 0) {
                for (var i = 0; i < videosLength; i++) {
                    if (PlaylistEditController.playlist.videos[i].id === video.id) {
                        return true;
                    }
                }
            }
            return false;
        }

        PlaylistEditController.loadPlaylist = loadPlaylist;
        function loadPlaylist(playlist) {
            return IonicPlaylistEditService.getPlaylist(playlist).then(
                function(playlist) {
                    if (playlist) {
                        PlaylistEditController.playlist = DefaultVariableService.getObject(
                            playlist
                        );
                    }

                    return playlist;
                }
            );
        }

        PlaylistEditController.setPlaylistVideos = setPlaylistVideos;
        function setPlaylistVideos(videos) {
            if (PlaylistEditController.playlist.videos.length <= videos.length) {
                PlaylistEditController.playlist.videos = videos;
            }
        }

        PlaylistEditController.setTags = setTags;
        function setTags(tags) {
            PlaylistEditController.playlist.tags = DefaultVariableService.getArray(
                tags
            );
        }

        PlaylistEditController.reset = reset;
        function reset() {
            PlaylistEditController.isDeletingPlaylist = false;

            PlaylistEditController.isEditingPlaylist = false;

            PlaylistEditController.flags = [];

            PlaylistEditController.resetPlaylist();

            PlaylistEditController.tags = [];
        }

        PlaylistEditController.resetPlaylist = resetPlaylist;
        function resetPlaylist() {
            PlaylistEditController.playlist = DefaultVariableService.getObject();
        }

        PlaylistEditController.init = init;
        function init() {
            PlaylistEditController.reset();
        }

        LoginService.register(PlaylistEditController);
    }
})();

(function() {
    'use strict';

    angular.module('ionicPlaylist').directive('ionicPlaylistEdit', ionicPlaylistEdit);

    function ionicPlaylistEdit() {
        return {
            controller:   'PlaylistEditController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                onCancel: '=',
                onDelete: '=',
                onEdit:   '=',
                playlist: '='
            },
            template:'<div><div class="row"><label class="item item-input item-floating-label col-xl-6"><span class="input-label">TITLE</span> <input type="text" placeholder="TITLE" data-ng-model="ctrl.playlist.title"></label> <label class="item item-input item-floating-label col-xl-6"><span class="input-label">WEEK NUMBER</span> <input type="text" placeholder="WEEK NUMBER" data-ng-model="ctrl.playlist.week"></label></div><div><ionic-upload target="ctrl.playlist"></ionic-upload></div><label class="item item-input item-stacked-label"><span class="input-label">TAGS</span><ionic-tag-select on-select="ctrl.setTags" target="ctrl.playlist.tags"></ionic-tag-select></label> <label class="item item-input item-stacked-label"><span class="input-label">ADD VIDEOS</span><ionic-video-filter on-select="ctrl.setPlaylistVideos" target="ctrl.playlist.videos"></ionic-video-filter></label><h3>ORDER VIDEOS</h3><ionic-ordered-list allow-delete="true" display-field="title" objects="ctrl.playlist.videos"></ionic-ordered-list><div class="row"><button class="button button-full accept-button col-xl-4" data-ng-click="ctrl.editPlaylist()" data-ng-disabled="ctrl.isEditingPlaylist || ctrl.isDeletingPlaylist">{{ ctrl.isEditingPlaylist ? \'EDITING...\' : \'EDIT\' }}</button> <button class="button button-full decline-button col-xl-4" data-ng-click="ctrl.deletePlaylist()" data-ng-disabled="ctrl.isEditingPlaylist || ctrl.isDeletingPlaylist">{{ ctrl.isDeletingPlaylist ? \'DELETING...\' : \'DELETE\' }}</button> <button class="button button-full col-xl-4" data-ng-click="ctrl.cancel()" data-ng-disabled="ctrl.isEditingPlaylist || ctrl.isDeletingPlaylist">CANCEL</button></div></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicPlaylist').service(
        'IonicPlaylistEditService',
        IonicPlaylistEditService
    );

    IonicPlaylistEditService.$inject = [
        'DefaultVariableService',
        'PlaylistEditService'
    ];

    function IonicPlaylistEditService(
        DefaultVariableService,
        PlaylistEditService
    ) {

        var IonicPlaylistEditService = this;
        
        IonicPlaylistEditService.editPlaylist = editPlaylist;
        function editPlaylist(playlist) {
            playlist = DefaultVariableService.getObject(
                playlist
            );

            var videos = DefaultVariableService.getArray(
                playlist.videos
            );
            var videosLength = videos.length;

            var playlistsVideos = DefaultVariableService.getArray(
                playlist.playlists_videos
            );
            var playlistsVideosLength = playlistsVideos.length;

            var newPlaylistVideos = [];
            for (var v = 0; v < videosLength; v++) {
                var video = videos[v];

                var hasPlaylistVideo = false;
                for (var pv = 0; pv < playlistsVideosLength; pv++) {
                    var playlistVideo = playlistsVideos[pv];

                    playlistVideo.found = DefaultVariableService.get(
                        playlistVideo.found,
                        false
                    );

                    if (playlistVideo.video_id === video.id) {
                        playlistVideo.position = v;

                        playlistVideo.found = true;

                        hasPlaylistVideo = true;

                        break;
                    }
                }

                if (!hasPlaylistVideo) {
                    var newPlaylistVideo = {
                        playlist_id: playlist.id,
                        video_id: video.id,
                        position: v
                    };

                    newPlaylistVideos.push(newPlaylistVideo);
                }
            }

            playlistsVideos = playlistsVideos.filter(
                function(playlistVideo) {
                    return playlistVideo.found;
                }
            );

            playlist.playlists_videos = playlistsVideos.concat(newPlaylistVideos);

            playlistsVideosLength = playlist.videos.length;
            for (var i = 0; i < playlistsVideosLength; i++) {
                delete playlist.videos[i].tags;
            }

            return PlaylistEditService.editPlaylist(playlist);
        }

        IonicPlaylistEditService.deletePlaylist = deletePlaylist;
        function deletePlaylist(playlist) {
            return PlaylistEditService.deletePlaylist(playlist);
        }

        IonicPlaylistEditService.getPlaylist = getPlaylist;
        function getPlaylist(playlist) {
            return PlaylistEditService.getPlaylist(playlist);
        }

        IonicPlaylistEditService.reset = reset;
        function reset() {

        }

        IonicPlaylistEditService.reset();

        return IonicPlaylistEditService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicPlaylist').controller(
        'PlaylistFilterController',
        PlaylistFilterController
    );
    
    PlaylistFilterController.$inject = [
        'DefaultVariableService',
        'LoginService',
        'PlaylistListService',
        '$scope'
    ];
    
    function PlaylistFilterController(
        DefaultVariableService,
        LoginService,
        PlaylistListService,
        $scope
    ) {
        var PlaylistFilterController = this;

        PlaylistFilterController.target = DefaultVariableService.getObject();

        $scope.$watch(
            'onSelect',
            function(onSelect) {
                PlaylistFilterController.onSelect = DefaultVariableService.getPromise(
                    onSelect
                );
            }
        );

        $scope.$watch(
            'target',
            function(target) {
                target = DefaultVariableService.getObject(
                    target
                );

                if (target.id !== 0 && target.id !== PlaylistFilterController.target.id) {
                    PlaylistFilterController.target = target;
                }
            }
        );

        PlaylistFilterController.clear = clear;
        function clear() {
            PlaylistFilterController.reset();
            PlaylistListService.reset();
        }
        
        PlaylistFilterController.loadPlaylists = loadPlaylists;
        function loadPlaylists(options) {
            PlaylistFilterController.isLoadingPlaylists = true;

            return PlaylistListService.getPage(options).then(
                function(data) {
                    if (data) {
                        PlaylistFilterController.playlists = DefaultVariableService.getArray(
                            data.playlists
                        );
                    }

                    return data;
                }
            ).finally(
                function() {
                    PlaylistFilterController.isLoadingPlaylists = false;
                }
            );
        }

        PlaylistFilterController.selectPlaylist = selectPlaylist;
        function selectPlaylist(playlist) {
            PlaylistFilterController.target = playlist;

            PlaylistFilterController.onSelect(playlist);
        }

        PlaylistFilterController.reset = reset;
        function reset() {
            PlaylistFilterController.isLoadingPlaylists = true;

            PlaylistFilterController.playlists = [];
        }

        PlaylistFilterController.init = init;
        function init() {
            PlaylistFilterController.reset();
            PlaylistFilterController.loadPlaylists();
        }

        LoginService.register(PlaylistFilterController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicPlaylist').directive(
        'ionicPlaylistFilter',
        ionicPlaylistFilter
    );

    function ionicPlaylistFilter() {
        return {
            controller:   'PlaylistFilterController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {
                onSelect: '=',
                target:   '='
            },
            template:'<div><ionic-filter objects="ctrl.playlists" on-filter="ctrl.loadPlaylists" on-select="ctrl.selectPlaylist" target="ctrl.target" title="title" toggle-message="SELECT A PLAYLIST..."></ionic-filter></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicPlaylist').controller(
        'PlaylistListController',
        PlaylistListController
    );

    PlaylistListController.$inject = [
        'DefaultImageService',
        'DefaultVariableService',
        'AssignedPlaylistListService',
        'LoginService',
        'RedirectService',
        'RequestManagementService',
        '$scope'
    ];

    function PlaylistListController(
        DefaultImageService,
        DefaultVariableService,
        AssignedPlaylistListService,
        LoginService,
        RedirectService,
        RequestManagementService,
        $scope
    ) {
        var PlaylistListController = this;

        $scope.$watch(
            'collapsible',
            function(collapsible) {
                PlaylistListController.collapsible = DefaultVariableService.get(
                    collapsible,
                    false
                );
            }
        );

        $scope.$watch(
            'options',
            function(options) {
                options = RequestManagementService.getRequest(
                    options
                );

                options = RequestManagementService.setParams(
                    options,
                    [
                        'Images',
                        'Videos'
                    ]
                );

                if (!RequestManagementService.hasOrder(options)) {
                    options = RequestManagementService.setOrder(
                        options,
                        [
                            'title'
                        ]
                    );
                }

                PlaylistListController.loadPlaylists(options);
            }
        );

        $scope.$watch(
            'showCount',
            function(showCount) {
                PlaylistListController.showCount = DefaultVariableService.get(
                    showCount,
                    false
                );
            }
        );

        PlaylistListController.clear = clear;
        function clear() {
            PlaylistListController.reset();
            AssignedPlaylistListService.reset();
        }

        PlaylistListController.forceRefresh = forceRefresh;
        function forceRefresh() {
            PlaylistListController.clear();
            PlaylistListController.init();

            PlaylistListController.loadPlaylists();
        }

        PlaylistListController.getPlaylistImage = getPlaylistImage;
        function getPlaylistImage(playlist) {
            return DefaultImageService.getPlaylistImage(playlist);
        }

        PlaylistListController.loadPlaylists = loadPlaylists;
        function loadPlaylists(options) {
            PlaylistListController.isLoadingPlaylists = true;

            return AssignedPlaylistListService.getMore(options).then(
                function(data) {
                    if (data) {
                        var playlists = DefaultVariableService.getArray(
                            data.playlists
                        );

                        PlaylistListController.playlists = AssignedPlaylistListService.removeEmptyPlaylists(
                            playlists
                        );

                        if (PlaylistListController.playlists.length === 0) {
                            PlaylistListController.isCollapsed = true;
                        }
                    }

                    PlaylistListController.canLoadMore = DefaultVariableService.get(
                        data.canLoadMore,
                        false
                    );

                    return data;
                }
            ).finally(
                function() {
                    PlaylistListController.isLoadingPlaylists = false;
                }
            );
        }

        PlaylistListController.loadWeeks = loadWeeks;
        function loadWeeks() {
            return AssignedPlaylistListService.getWeeks().then(
                function(weeks) {
                    if (weeks) {
                        PlaylistListController.weeks = weeks;
                    }

                    return weeks;
                }
            );
        }

        PlaylistListController.selectPlaylist = selectPlaylist;
        function selectPlaylist(playlist) {
            RedirectService.goTo(
                PlaylistListController.sref,
                {
                    playlistId: playlist.id
                }
            );
        }

        PlaylistListController.toggleCollapse = toggleCollapse;
        function toggleCollapse() {
            PlaylistListController.isCollapsed = DefaultVariableService.toggle(
                PlaylistListController.isCollapsed,
                true
            );
        }

        PlaylistListController.reset = reset;
        function reset() {
            PlaylistListController.canLoadMore = true;

            PlaylistListController.isLoadingPlaylists = true;

            PlaylistListController.sref = DefaultVariableService.get(
                PlaylistListController.sref,
                'app.playlist'
            );

            PlaylistListController.isCollapsed = true;

            PlaylistListController.title = DefaultVariableService.get(
                PlaylistListController.title,
                false
            );

            PlaylistListController.playlists = [];

            PlaylistListController.week = -1;
        }

        PlaylistListController.init = init;
        function init() {
            PlaylistListController.reset();
        }

        LoginService.register(PlaylistListController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicPlaylist').directive(
        'ionicPlaylistList',
        ionicPlaylistList
    );

    function ionicPlaylistList() {
        return {
            bindToController: {
                sref:  '@',
                title: '@'
            },
            controller:   'PlaylistListController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                collapsible: '=',
                options:     '=',
                showCount:   '='
            },
            template:'<ion-list class="list"><ion-refresher data-ng-if="!ctrl.collapsible" pulling-text="FORCE REFRESH" on-refresh="ctrl.forceRefresh()"></ion-refresher><div data-ng-if="ctrl.title || ctrl.collapsible" class="row item bar bar-stable buttons" data-ng-click="ctrl.toggleCollapse()"><h1 class="title">{{ ctrl.title }}&nbsp; <span data-ng-if="ctrl.showCount && !ctrl.isLoadingPlaylists">({{ ctrl.playlists.length }})</span></h1><button data-ng-if="ctrl.collapsible" class="button button-icon" data-ng-disabled="ctrl.playlists.length === 0">{{ ctrl.isCollapsed ? \'SHOW\' : \'HIDE\' }} <i class="icon" data-ng-class="ctrl.isCollapsed ? \'ion-arrow-expand\' : \'ion-arrow-shrink\'"></i></button></div><div class="animated" data-ng-class="ctrl.isCollapsed && ctrl.collapsible ? \'fadeOutUp\' : \'fadeInDown\'" data-ng-if="!ctrl.collapsible || (ctrl.collapsible && !ctrl.isCollapsed)"><div class="row" data-ng-repeat="playlist in ctrl.playlists" data-ng-if="$index % 2 === 0" data-ng-init="playlistListIndex = $index"><a class="card col" data-ng-click="ctrl.selectPlaylist(ctrl.playlists[playlistListIndex + i])" data-ng-repeat="i in [0,1]" data-ng-if="(playlistListIndex + i) < ctrl.playlists.length"><div class="item item-divider">{{ ctrl.playlists[playlistListIndex + i].title }}</div><div class="item item-text-wrap"><p data-ng-if="ctrl.playlists[playlistListIndex + i].week === ctrl.weeks" class="playlistNewBadge">NEW</p><img class="full-image image-card-image" data-ng-src="{{ ctrl.getPlaylistImage(ctrl.playlists[playlistListIndex + i]) }}"></div></a></div><div class="item bar bar-stable" data-ng-if="!ctrl.canLoadMore"><h1 class="title">{{ ctrl.playlists.length === 0 ? \'NO PLAYLISTS\' : \'NO MORE PLAYLISTS\' }}</h1></div><ionic-infinite-scroll can-load-more="ctrl.canLoadMore" immediate-check="false" load-more="ctrl.loadPlaylists"></ionic-infinite-scroll></div><span data-ng-if="ctrl.isLoadingPlaylists"><ion-spinner class="centerSpinner"></ion-spinner></span></ion-list>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicPlaylist').controller(
        'PlaylistManagementController', 
        PlaylistManagementController
    );

    PlaylistManagementController.$inject = [
        'DefaultVariableService',
        'LoginService',
        'PlaylistListService'
    ];

    function PlaylistManagementController(
        DefaultVariableService,
        LoginService,
        PlaylistListService
    ) {
        var PlaylistManagementController = this;

        PlaylistManagementController.clear = clear;
        function clear() {
            PlaylistManagementController.reset();
            PlaylistListService.reset();
        }

        PlaylistManagementController.onCreate = onCreate;
        function onCreate() {
            PlaylistManagementController.toggleCreator();

            PlaylistManagementController.loadPlaylists();
        }

        PlaylistManagementController.onDelete = onDelete;
        function onDelete() {
            PlaylistManagementController.setPlaylist();

            PlaylistManagementController.loadPlaylists();
        }

        PlaylistManagementController.onEdit = onEdit;
        function onEdit() {
            PlaylistManagementController.setPlaylist();

            PlaylistManagementController.loadPlaylists();
        }

        PlaylistManagementController.loadPlaylists = loadPlaylists;
        function loadPlaylists(options) {
            PlaylistManagementController.isLoadingPlaylists = true;

            return PlaylistListService.getPage(
               options
            ).then(
                 function(data) {
                     if (data) {
                         PlaylistManagementController.playlists = DefaultVariableService.getArray(
                             data.playlists
                         );

                         PlaylistManagementController.count = DefaultVariableService.getInteger(
                             data.count
                         );
                     }

                     return data;
                 }
            ).finally(
                function() {
                    PlaylistManagementController.isLoadingPlaylists = false;
                }
            );
        }

        PlaylistManagementController.setPlaylist = setPlaylist;
        function setPlaylist(playlist) {
            PlaylistManagementController.playlist = DefaultVariableService.getObject(
                playlist
            );
        }

        PlaylistManagementController.reset = reset;
        function reset() {
            PlaylistManagementController.columns = [
                {
                    position: 1,
                    title:    'TITLE',
                    key:      'title'
                },
                {
                    position: 2,
                    title:    'WEEK',
                    key:      'week'
                },
                {
                    position: 3,
                    title:    'VIDEOS',
                    key:      'video_count',
                    canOrder: false
                }
            ];
            
            PlaylistManagementController.isLoadingPlaylists = true;

            PlaylistManagementController.resetPlaylist();

            PlaylistManagementController.playlists = [];

            PlaylistManagementController.showCreator = false;
        }

        PlaylistManagementController.resetPlaylist = resetPlaylist;
        function resetPlaylist() {
            PlaylistManagementController.playlist = DefaultVariableService.getObject();
        }

        PlaylistManagementController.toggleCreator = toggleCreator;
        function toggleCreator() {
            PlaylistManagementController.showCreator = !PlaylistManagementController.showCreator;
        }

        PlaylistManagementController.init = init;
        function init() {
            PlaylistManagementController.reset();
            PlaylistManagementController.loadPlaylists();
        }

        LoginService.register(PlaylistManagementController);
    }
})();

(function() {
    'use strict';

    angular.module('ionicPlaylist').directive(
        'ionicPlaylistManagement',
        ionicPlaylistManagement
    );

    function ionicPlaylistManagement() {
        return {
            controller:   'PlaylistManagementController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {},
            template:'<div data-ng-if="!ctrl.showCreator"><button data-ng-if="!ctrl.showCreator" class="button button-full accept-button" data-ng-click="ctrl.toggleCreator()">CREATE NEW PLAYLIST</button><ionic-data-table data-ng-if="ctrl.playlist.id === 0" columns="ctrl.columns" on-request="ctrl.loadPlaylists" on-select="ctrl.setPlaylist"></ionic-data-table><ionic-playlist-edit data-ng-if="ctrl.playlist.id !== 0" playlist="ctrl.playlist" on-cancel="ctrl.resetPlaylist" on-delete="ctrl.onEdit" on-edit="ctrl.onEdit"></ionic-playlist-edit></div><div data-ng-if="ctrl.showCreator"><ionic-playlist-create on-cancel="ctrl.toggleCreator" on-create="ctrl.onCreate"></ionic-playlist-create></div>'
        };
    }
})();